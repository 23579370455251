<template>
  <div class="login-form">
    <div class="text-center">
      <img src="/assets/logo.png" alt="" srcset="" height="100px" />
    </div>
    <hr />
    <h5 class="text-center">Login Page </h5>
    <b-alert variant="danger" v-if="errors" show>{{ errors }}</b-alert>

    <b-form @submit.prevent="onSubmit">
      <b-form-group id="input-group-1"  label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="form.username"
          type="text"
          placeholder="Enter username"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="form.password"
          placeholder="Enter Password"
          required
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="outline-success" block>
        <span v-if="processing">
          <b-spinner small type="grow"></b-spinner>
          Processing...
        </span>
        <span v-else> Login </span>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      errors: false,
      processing: false,
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async onSubmit(event) {
      event.preventDefault();
      this.processing = true;
      this.$store
        .dispatch("LogIn", this.form)
        .then((res) => {
          console.log(res);
          this.processing = false;
          this.$router.push("/");
        })
        .catch((err) => {
          this.processing = false;
          this.errors = err.response.data.message;
          setTimeout(() => (this.errors = false), 3000);
        });
    },
  },
};
</script>
<style>
.login-form {
  border: #e3e3e3;
  border-radius: 11px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin: 10vh auto;
  max-width: 450px;
}
</style>
